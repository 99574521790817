'use strict';

var React = require("react");
var App$SFUI = require("./App.bs.js");
var RemoteAPI = require("@softwarefactory-project/res-remoteapi/src/RemoteAPI.bs.js");
var ReactDom = require("react-dom");

var RealApp = App$SFUI.Main(RemoteAPI.BsFetch);

var root = document.querySelector("#root");

if (root == null) {
  console.log("Can't find #root element!");
} else {
  ReactDom.render(React.createElement(RealApp.make, {}), root);
}

exports.RealApp = RealApp;
/* RealApp Not a pure module */
