'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var RemoteData = require("remotedata-re/src/RemoteData.bs.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactCore = require("@patternfly/react-core");

function some(x) {
  return Caml_option.some(x);
}

function actionMap(action, f) {
  if (typeof action === "number") {
    return /* NetworkRequestBegin */0;
  } else if (action.TAG === /* NetworkRequestSuccess */0) {
    return {
            TAG: /* NetworkRequestSuccess */0,
            _0: Curry._1(f, action._0)
          };
  } else {
    return {
            TAG: /* NetworkRequestError */1,
            _0: action._0
          };
  }
}

function removeAPIcall(param) {
  return param.replace("API call failed: ", "");
}

function renderError(e) {
  if (typeof e === "number" || e.TAG !== /* Failure */1) {
    return null;
  } else {
    return React.createElement(ReactCore.Alert, {
                title: e._0.replace("API call failed: ", ""),
                variant: "danger"
              });
  }
}

function chainCall(start, next) {
  return start.then(function (result) {
              return Promise.resolve(Belt_Result.flatMap(result, (function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: Curry._1(next, undefined)
                                      };
                              })));
            });
}

function toLoading(data) {
  return {
          TAG: /* Loading */0,
          _0: RemoteData.withDefault(undefined, RemoteData.map((function (d) {
                      return Caml_option.some(d);
                    }), data))
        };
}

function updateRemoteData(data, action) {
  if (typeof action === "number") {
    return toLoading(data);
  } else if (action.TAG === /* NetworkRequestSuccess */0) {
    return {
            TAG: /* Success */2,
            _0: action._0
          };
  } else {
    return {
            TAG: /* Failure */1,
            _0: action._0
          };
  }
}

function $$const(a, param) {
  return a;
}

function note(o, e) {
  if (o !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Caml_option.valFromOption(o)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: e
          };
  }
}

function deccoErrorToResponse(r) {
  if (r.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: r._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: r._0.message
          };
  }
}

function responseToAction(response) {
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* NetworkRequestSuccess */0,
            _0: response._0
          };
  } else {
    return {
            TAG: /* NetworkRequestError */1,
            _0: response._0
          };
  }
}

function getWhenNeeded(state, dispatch, param) {
  if (typeof state === "number") {
    Curry._1(dispatch, undefined);
  }
  
}

function debugChain(r, m) {
  console.log("[DEBUG] " + (m + ":"), r);
  return r;
}

function API(Fetcher) {
  var getMaybe = function (url, decode, dispatch) {
    Curry._1(dispatch, /* NetworkRequestBegin */0);
    return Curry._1(Fetcher.get, url).then(function (result) {
                var tmp;
                if (result.TAG === /* Ok */0) {
                  var json = result._0;
                  tmp = json !== undefined ? actionMap(responseToAction(deccoErrorToResponse(Curry._1(decode, Caml_option.valFromOption(json)))), (function (r) {
                            return Caml_option.some(r);
                          })) : ({
                        TAG: /* NetworkRequestSuccess */0,
                        _0: undefined
                      });
                } else {
                  tmp = {
                    TAG: /* NetworkRequestError */1,
                    _0: result._0
                  };
                }
                return Promise.resolve(Curry._1(dispatch, tmp));
              });
  };
  var get = function (url, decode, dispatch) {
    Curry._1(dispatch, /* NetworkRequestBegin */0);
    return Curry._1(Fetcher.get, url).then(function (result) {
                var tmp;
                tmp = result.TAG === /* Ok */0 ? responseToAction(Belt_Result.flatMap(note(result._0, "Need json"), (function (json) {
                              return deccoErrorToResponse(Curry._1(decode, json));
                            }))) : ({
                      TAG: /* NetworkRequestError */1,
                      _0: result._0
                    });
                return Promise.resolve(Curry._1(dispatch, tmp));
              });
  };
  var $$delete = function (url, dispatch) {
    Curry._1(dispatch, /* NetworkRequestBegin */0);
    return Curry._1(Fetcher.$$delete, url).then(function (result) {
                var tmp;
                tmp = result.TAG === /* Ok */0 ? ({
                      TAG: /* NetworkRequestSuccess */0,
                      _0: undefined
                    }) : ({
                      TAG: /* NetworkRequestError */1,
                      _0: result._0
                    });
                Curry._1(dispatch, tmp);
                return Promise.resolve(result);
              });
  };
  var putOrPost = function (action, decode, dispatch) {
    Curry._1(dispatch, /* NetworkRequestBegin */0);
    return Curry._1(action, undefined).then(function (resp) {
                return Promise.resolve(Belt_Result.flatMap(resp, (function (mjson) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: Curry._1(dispatch, responseToAction(Belt_Result.flatMap(debugChain(note(mjson, "Need json!"), "Initial json"), (function (json) {
                                                          return deccoErrorToResponse(debugChain(Curry._1(decode, json), "json decode"));
                                                        }))))
                                        };
                                })));
              });
  };
  var post = function (url, data) {
    return function (param, param$1) {
      return putOrPost((function (param) {
                    return Curry._2(Fetcher.post, url, data);
                  }), param, param$1);
    };
  };
  var put = function (url, data) {
    return function (param, param$1) {
      return putOrPost((function (param) {
                    return Curry._2(Fetcher.put, url, data);
                  }), param, param$1);
    };
  };
  var justPost = function (url, data, dispatch) {
    Curry._1(dispatch, /* NetworkRequestBegin */0);
    return Curry._2(Fetcher.post, url, Caml_option.some(data)).then(function (result) {
                var tmp;
                tmp = result.TAG === /* Ok */0 ? ({
                      TAG: /* NetworkRequestSuccess */0,
                      _0: undefined
                    }) : ({
                      TAG: /* NetworkRequestError */1,
                      _0: result._0
                    });
                Curry._1(dispatch, tmp);
                return Promise.resolve(result);
              });
  };
  var useGet = function (url, decoder) {
    var match = React.useState(function () {
          return /* NotAsked */0;
        });
    var setState = match[1];
    var state = match[0];
    var dispatch = function (param) {
      get(url, decoder, (function (r) {
              var s = updateRemoteData(state, r);
              return Curry._1(setState, (function (_prevState) {
                            return s;
                          }));
            }));
      
    };
    return [
            state,
            dispatch
          ];
  };
  var useAutoGet = function (url, decoder) {
    var match = useGet(url, decoder);
    var dispatch = match[1];
    var state = match[0];
    React.useEffect((function () {
            return getWhenNeeded(state, dispatch, undefined);
          }), []);
    return state;
  };
  var useSimplePut = function (url, decoder, put_decoder) {
    var match = React.useState(function () {
          return /* NotAsked */0;
        });
    var setState = match[1];
    var state = match[0];
    React.useEffect((function () {
            return getWhenNeeded(state, (function (param) {
                          get(url, decoder, (function (r) {
                                  var s = updateRemoteData(state, r);
                                  return Curry._1(setState, (function (_prevState) {
                                                return s;
                                              }));
                                }));
                          
                        }), undefined);
          }), []);
    return [
            state,
            (function (obj) {
                put(url, obj)(put_decoder, (function (r) {
                        var s = updateRemoteData(state, r);
                        return Curry._1(setState, (function (_prevState) {
                                      return s;
                                    }));
                      }));
                
              })
          ];
  };
  var Hook = {
    useGet: useGet,
    useAutoGet: useAutoGet,
    useSimplePut: useSimplePut
  };
  return {
          getMaybe: getMaybe,
          get: get,
          $$delete: $$delete,
          putOrPost: putOrPost,
          post: post,
          put: put,
          justPost: justPost,
          Hook: Hook
        };
}

exports.some = some;
exports.actionMap = actionMap;
exports.removeAPIcall = removeAPIcall;
exports.renderError = renderError;
exports.chainCall = chainCall;
exports.toLoading = toLoading;
exports.updateRemoteData = updateRemoteData;
exports.$$const = $$const;
exports.note = note;
exports.deccoErrorToResponse = deccoErrorToResponse;
exports.responseToAction = responseToAction;
exports.getWhenNeeded = getWhenNeeded;
exports.debugChain = debugChain;
exports.API = API;
/* react Not a pure module */
