'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var V2$SF = require("@softwarefactory-project/re-sf/src/V2.bs.js");
var Info$SF = require("@softwarefactory-project/re-sf/src/Info.bs.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Resources$SF = require("@softwarefactory-project/re-sf/src/Resources.bs.js");
var RemoteApi$SFUI = require("./RemoteApi.bs.js");

function Hook(Fetcher) {
  var RemoteApi = RemoteApi$SFUI.API(Fetcher);
  var use = function (param) {
    return Curry._2(RemoteApi.Hook.useAutoGet, "/api/info.json", Info$SF.decode);
  };
  var Info = {
    use: use
  };
  var use$1 = function (default_tenant) {
    return Curry._2(RemoteApi.Hook.useGet, "/api/resources.json", (function (json) {
                  return Belt_Result.flatMap(Resources$SF.decode(json), (function (resv1) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: V2$SF.fromV1(default_tenant, resv1)
                                      };
                              }));
                }));
  };
  var Resources = {
    use: use$1
  };
  return {
          RemoteApi: RemoteApi,
          Info: Info,
          Resources: Resources
        };
}

exports.Hook = Hook;
/* RemoteApi-SFUI Not a pure module */
